@use '@/styles/utils/mixins.scss' as *;

.offersRowWrapper {
  margin-right: 20%;
  @media (max-width: 1200px) {
    margin-right: 15%;
  }
  @media (max-width: 991.98px) {
    margin-right: 0;
  }
}
.footerLogo {
  height: 16px;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 40px;
}
.returnToHome {
  display: block;
  list-style: none;
  margin-bottom: 20px;
  font-weight: 600;
}
.offerList {
  ul {
    li {
      display: block;
      list-style: none;
      margin-bottom: 20px;
      a {
        color: $kiaPolarWhite;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        .heartIcon {
          width: 24px;
          height: 24px;
          display: inline-block;
          margin-left: 10px;
        }
        @include tab() {
          font-size: 20px;
        }
        &.linkIcon {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  @include tab() {
    padding-bottom: 20px;
  }
}
