@use '@/styles/utils/mixins.scss' as *;

.chakra-modal__content-container:has(#chakra-modal-mobileDrawer) {
  z-index: 9999;
}

#chakra-modal-mobileDrawer {
  background: $kiaMidnightBlack;
  .mobileDrawerHeader {
    background-color: $kiaMidnightBlack;
    display: flex;
    align-items: center;
    height: 83px;
    .logo {
      display: inline-block;
    }
    .menuItem {
      font-family: 'KiaSignature';
      font-size: 16px;
      line-height: 19.2px;
      font-weight: 600;
      color: $kiaPolarWhite;
      margin: 0 auto;
      padding-right: 32px;
    }
  }
  .mobileDrawerClose {
    background: transparent;
    color: white !important;
    opacity: 1;
    visibility: visible;
    width: 32px;
    height: 32px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    &:focus-visible,
    &:focus {
      box-shadow: none !important;
    }
  }
  @media screen and (max-width: 320px) {
    max-height: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
  }
}
.hamburgerIcon,
.MenuCloseIcon {
  min-width: 83px;
  min-height: 83px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileDrawerBody {
  height: calc(100vh - 100px);
  overflow-y: auto;
  padding-bottom: 100px !important;
  -webkit-overflow-scrolling: touch;
}

.chakra-modal__overlay {
  background: rgba(0, 0, 0, 0.4) !important;
}
