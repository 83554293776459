@use '@/styles/utils/mixins.scss' as *;

.textDark {
  color: $background;
}

.sectionTitle {
  position: relative;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'KiaSignature';
    font-weight: 600;
    font-style: normal;
    &.titleLg {
      font-size: 36px;
      line-height: 43.2px;

      @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 36px;
      }
    }
    &.titleXs {
      font-size: 16px;
      line-height: 19.2px;
    }
  }
  &.extraMargin {
    margin: 2rem 0;
  }
}

.megaMenuLink {
  padding: 13px 0;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600;
  color: $kiaPolarWhite !important;
  position: relative;
  @media screen and (min-width: 992px) and (max-width: 1145px) {
    font-size: 12px;
  }
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 11px;
    height: 1px;
    width: 0;
    background-color: $kiaPolarWhite;
    transition: width 0.3s;
  }
  &:hover::before,
  &:focus::before,
  &:active::before,
  &.active::before {
    width: 100%;
    transition: width 0.3s;
  }
  .menuIcon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: auto;
    right: -25px;
    z-index: 1;
    margin-top: -8px;
    transition: all ease-in-out 0.3s;
  }
  &:hover,
  &:focus,
  &:active,
  &.active {
    .menuIcon {
      transform: rotate(180deg);
    }
  }
  &[aria-expanded='true'] {
    .menuIcon {
      transform: rotate(180deg);
    }
  }
  @media screen and (max-width: 991.98px) {
    display: none;
  }
}

.megaMenuWrapper {
  color: $kiaMidnightBlack;
  box-shadow: none;
  @media screen and (max-width: 991.98px) {
    bottom: 0 !important;
    opacity: 1 !important;
    visibility: visible !important;
    transform: scale(1) translateZ(0px) !important;
    top: -24px !important;
  }
  .overlay {
    position: fixed;
    top: 83px;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 83px);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -2;
    transition: all 700ms linear;
    @media screen and (max-width: 991.98px) {
      display: none;
    }
  }
}

.megamenuHeader {
  background-color: $background;
  padding: 24px 0;

  @media screen and (max-width: 1366px) {
    padding: 24px;
  }
  @media screen and (max-width: 991.98px) {
    padding: 12px 0px 12px 24px;
  }
  .megamenuFilter {
    display: flex;
    flex-wrap: wrap;
    max-width: 1326px;
    width: 100%;
    @media screen and (min-width: 992px) {
      margin-left: auto;
      margin-right: auto;
      justify-content: end;
    }
    @media screen and (max-width: 991.98px) {
      white-space: pre-wrap;
      overflow-x: auto;
      max-width: 100%;
      width: 100%;
      flex-wrap: nowrap;
      flex-direction: column;
    }
    .badge {
      background-color: $keyline;
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      color: $kiaMidnightBlack;
      padding: 9px 15px 7px;
      border-radius: 15px;
      text-decoration: none;
      margin-right: 16px;
      min-width: fit-content;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      @media screen and (max-width: 991.98px) {
        margin: 12px 8px 12px 0;
      }
      cursor: pointer;
      &:hover,
      &:active,
      &.active {
        background-color: $kiaMidnightBlack;
        color: $kiaPolarWhite;
      }
    }
  }
  .megamenuFilterClose {
    position: static;
    top: 0;
    right: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: transparent;
    box-shadow: none;
    &:hover {
      background-color: transparent;
    }
  }
}

.megamenuBodyWrap {
  padding: 56px 0;
  max-height: calc(100vh - 149px);
  overflow-y: auto;
  background-color: $kiaPolarWhite;

  @media screen and (max-width: 1366px) {
    padding: 56px 24px;
  }
  @media screen and (max-width: 991.98px) {
    max-height: initial;
  }
  .megamenuBody {
    max-width: 1326px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.alreadySeenVehicles {
  &.megamenuBodyWrap {
    padding-top: 0;
    .megamenuBodyTop {
      background-color: $selectedOption;
      padding: 56px 0;
      margin-bottom: 56px;
      @media screen and (max-width: 1366px) {
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}

.vehicleCard {
  max-width: 240px;
  width: 100%;
  @media screen and (max-width: 576px) {
    max-width: 156px;
  }
  .carLink {
    display: block;
    margin-bottom: 12px;
  }
  .carImage {
    height: 140px;
    object-fit: contain;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    @media screen and (max-width: 767px) {
      height: 90px;
      max-width: max-content;
      margin: 0;
    }
  }
  .carDetails {
    .modelName {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: $kiaMidnightBlack;
      display: inline-block;
      margin-bottom: 5px;
    }
    .carPrice {
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 600;
      color: $kiaMidnightBlack;
      margin-bottom: 5px;
      span {
        font-size: 12px;
        line-height: 14.4px;
        display: inline-block;
        vertical-align: bottom;
      }
    }
    .carCTA {
      @media screen and (max-width: 576px) {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
      }
    }
    .themeLink {
      display: inline-block;
      margin-right: 40px;
      @media screen and (max-width: 576px) {
        display: inline-block;
        margin: 0 0 25px 0px;
        .menuIcon {
          position: static;
          margin-top: 0;
          margin-left: 10px;
        }
        &:hover::before,
        &:focus::before,
        &:active::before {
          width: 0;
          transition: width 0.3s;
        }
      }
    }
  }
}

.themeLink {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600;
  color: $kiaMidnightBlack;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
  &:hover {
    color: $kiaMidnightBlack;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 0;
    background-color: $kiaMidnightBlack;
    transition: width 0.3s;
  }
  &:hover::before,
  &:focus::before,
  &:active::before {
    width: 100%;
    transition: width 0.3s;
  }
  .menuIcon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: auto;
    right: -15px;
    z-index: 1;
    margin-top: -5px;
    transition: all ease-in-out 0.3s;
  }
  &:hover {
    text-decoration: none !important;
  }
}

.toolsCard {
  margin: 25px auto;
  width: 100%;
  max-width: 280px;
  @media screen and (min-width: 480px) {
    max-width: 100%;
  }
  @media screen and (min-width: 768px) {
    max-width: 280px;
    padding: 0 25px;
  }

  .toolIcon {
    width: 56px;
    height: 56px;
    margin: 0 auto 16px;
  }
  .themeLink {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 16px;
    @media screen and (max-width: 991.98px) {
      font-size: 16px;
      line-height: 19.2px;
    }
    .menuIcon {
      margin-top: -12px;
      right: -25px;
      @media screen and (max-width: 576px) {
        margin-top: -14px;
      }
    }
  }
  .toolDescription {
    font-size: 14px;
    line-height: 19.6px;
  }
}

.megaMenuList {
  padding-right: 20px;
  @media screen and (min-width: 768px) {
    border-right: 1px solid $navigationBottom;
  }
  ul {
    li {
      a {
        font-size: 16px;
        line-height: 22.4px;
        color: $kiaMidnightBlack;
        transition: all ease-in-out 0.3s;
        &:hover {
          color: $kiaBattleshipGrey;
        }
      }
    }
  }
}
