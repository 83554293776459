@use '@/styles/utils/mixins.scss' as *;
nav {
  .searchDrawerBtn {
    background-color: $description !important;
    color: $kiaPolarWhite !important;
    margin: 0;
  }
  .searchDrawerCloseBtn {
    background-color: $kiaPolarWhite !important;
    color: $kiaMidnightBlack !important;
    margin-top: -83px;
    display: flex;
    margin-left: auto;
    max-width: min-content;
  }
  .searchDrawerBtn,
  .searchDrawerBtnClose {
    font-family: 'KiaSignature';
    font-size: 14px;
    font-weight: 600;
    min-height: 83px;
    min-width: 160px;
    cursor: pointer;
    @media screen and (min-width: 992px) and (max-width: 1060px) {
      min-width: 120px;
    }
    @media screen and (max-width: 991.98px) {
      min-width: 83px;
    }
    .closeIcon {
      width: 20px;
      height: 20px;
      margin-top: -2px;
      margin-right: 5px;
    }
    .searchIcon {
      margin-top: -2px;
      margin-right: 5px;
    }
  }
  .headerGlobalSearchBtnDesk {
    display: none;
    @media screen and (min-width: 992px) {
      display: block;
      position: absolute;
      right: 0;
    }
  }
  .headerGlobalSearchBtnMob {
    display: none;
    @media screen and (max-width: 991.98px) {
      display: block;
    }
  }
}

#chakra-modal-globalSearchWrap {
  top: 83px !important;
  background-color: $kiaPolarWhite;
  .searchDrawerCloseBtn {
    background-color: $kiaPolarWhite !important;
    color: $kiaMidnightBlack !important;
    margin-top: -83px;
    display: flex;
    margin-left: auto;
    max-width: min-content;
  }
  .searchDrawerBtn,
  .searchDrawerCloseBtn {
    font-family: 'KiaSignature';
    font-size: 14px;
    font-weight: 600;
    min-height: 83px;
    min-width: 160px;
    cursor: pointer;
    @media screen and (min-width: 992px) and (max-width: 1060px) {
      min-width: 120px;
    }
    @media screen and (max-width: 991.98px) {
      min-width: 83px;
      margin-right: 83px;
      .hideMobileClose {
        display: none;
      }
    }
    .closeIcon {
      width: 20px;
      height: 20px;
      margin-top: -2px;
      @media screen and (min-width: 992px) {
        margin-right: 5px;
      }
    }
    .searchIcon {
      margin-top: -2px;
      margin-right: 5px;
    }
  }
  .SearchBox {
    .iconSearch {
      width: 14px;
      height: 14px;
    }
    .iconClose {
      width: 18px;
      height: 18px;
    }
  }
  .SearchHeader {
    background-color: $kiaPolarWhite;
    .ais-SearchBox {
      .ais-SearchBox-form {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $keyline;
        border-radius: 6px;
        padding: 0px 15px;
        .ais-SearchBox-input {
          order: 2;
          width: 100%;
          padding: 12px 0px 12px 10px;
          background-color: $kiaPolarWhite;
          &:focus,
          &:focus-visible {
            border: 0;
            box-shadow: none;
            outline: none;
          }
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:active,
        input:-webkit-autofill:focus {
          background-color: $kiaPolarWhite !important;
        }
        // clears the 'X' from Internet Explorer
        input[type='search']::-ms-clear {
          display: none;
          width: 0;
          height: 0;
        }
        input[type='search']::-ms-reveal {
          display: none;
          width: 0;
          height: 0;
        }
        // clears the 'X' from Chrome
        input[type='search']::-webkit-search-decoration,
        input[type='search']::-webkit-search-cancel-button,
        input[type='search']::-webkit-search-results-button,
        input[type='search']::-webkit-search-results-decoration {
          display: none;
        }

        .ais-SearchBox-submit {
          order: 1;
          width: 14px;
          height: 14px;
          .ais-SearchBox-submitIcon {
            width: 100%;
            height: 100%;
          }
        }
        .ais-SearchBox-reset {
          order: 3;
        }
      }
    }
  }
  .SearchResultArea {
    .headingElement {
      position: relative;
      z-index: 2;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'KiaSignature';
        font-weight: 600;
        font-style: normal;
        &.titleXl {
          font-size: 60px;
          line-height: 60px;
          @include tab() {
            font-size: 36px;
            line-height: 43.2px;
          }
        }
        &.titleLg {
          font-size: 48px;
          line-height: 1;
          @include tab() {
            font-size: 36px;
            line-height: 1;
          }
        }
        &.titleMd {
          font-size: 20px;
          line-height: 1;
        }
        &.titleXs {
          font-size: 16px;
          line-height: 19.2px;
        }
      }
    }
    .searchFilterChip {
      background-color: $keyline;
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      color: $kiaMidnightBlack;
      padding: 9px 15px 7px;
      border: 1px solid $kiaMidnightBlack;
      border-radius: 15px;
      text-decoration: none;
      min-width: fit-content;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      cursor: pointer;
      &:hover,
      &:active,
      &.active {
        background-color: $kiaMidnightBlack;
        color: $kiaPolarWhite;
      }
    }
    .listLink {
      ul {
        li {
          a {
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 400;
            color: $kiaMidnightBlack;
            text-decoration: none;
            transition: all ease-in-out 0.3s;
            &:hover {
              color: $kiaBattleshipGrey;
            }
          }
        }
      }
    }
    .searchLatestNews {
      .SearchNewsThumb {
        margin-right: 16px;
      }
    }
  }
}
