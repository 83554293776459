@use '@/styles/utils/mixins.scss' as *;

.offersWrapper {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-around;

  @media (max-width: 1385px) {
    flex-wrap: wrap;
  }

  @include tab() {
    flex-direction: column;
    margin-left: 0;
  }

  .offersItem {
    width: 25%;
    margin-left: 10px;

    @media (max-width: 1385px) {
      margin-left: 0;
    }

    @include tab() {
      margin-left: 0;
      width: 100%;
      border-top: 1px solid $kiaCoolGrey;
      min-height: 45px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 15px 0px;

      &:last-child {
        border-bottom: 1px solid $kiaCoolGrey;
      }
    }

    &:first-child {
      margin-left: 0;
    }
  }
  .offersTitle {
    color: $background;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;

    @include tab() {
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
    @include mob() {
      font-size: 16px;
    }
  }
  .offersItemText {
    color: $kiaCoolGrey;
    font-size: 14px;
    line-height: 19.6px;
    display: block;
    margin-top: 15px;
    margin-bottom: 5px;
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
    white-space: pre-line;
    &:hover {
      color: $kiaPolarWhite;
    }
  }

  .form {
    cursor: pointer;
  }

  .offersItemMore {
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
  }

  .offersItemText:focus {
    box-shadow: none;
  }
}

.offersItemsWrapper {
  margin-bottom: 20px;

  @include tab() {
    margin-bottom: 10px;
    display: none;
  }
}

.iconWrapper {
  display: none;

  @include tab() {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.showSubMenu {
  display: block;
  width: 100%;
}
