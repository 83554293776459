@use '@/styles/utils/mixins.scss' as *;

.footer {
  background: $kiaMidnightBlack;
  color: $kiaPolarWhite;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 3rem;
  padding-right: 3rem;

  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
  }

  @media screen and (max-width: 1120px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include tab() {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include tab() {
    padding-left: 24px;
    padding-right: 24px;
  }
}
