@use '@/styles/utils/mixins.scss' as *;
.mobileDrawerHeader {
  background-color: $kiaMidnightBlack;
  display: flex;
  align-items: center;
  height: 83px;
  .menuItem {
    font-family: 'KiaSignature';
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 600;
    color: $kiaPolarWhite;
    margin: 0 auto;
    text-align: center;
  }
}
.mobileDrawerClose {
  &:focus-visible,
  &:focus {
    box-shadow: none !important;
  }
}
.mobileDrawerBody {
  @media screen and (min-width: 992px) {
    background-color: $kiaPolarWhite;
  }
  @media screen and (max-width: 991.98px) {
    background-color: $kiaMidnightBlack;
    padding-top: 24px !important;
  }
  overflow-x: initial !important;
  overflow-y: auto !important;
}
.mobileMenuLink {
  font-family: 'KiaSignature';
  padding: 30px 0;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid $kiaPolarWhite;
  }
  cursor: pointer;
  .mobileMenuLinkText {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: $kiaPolarWhite;
    display: flex;
    align-items: center;
    a {
      &:hover,
      &:active,
      &:focus {
        color: $kiaPolarWhite;
      }
    }
  }
}
.mobileMenuIcon {
  margin-left: auto;
}
